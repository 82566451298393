import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import store from '../store'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/cadastro/cashback',
    name: 'Cadastrousuario',
    component: () => import('../views/cadastros/UsuarioPortal.vue'),
    meta: {
      unauthenticated: true
    }
  },
  {
    path: '/cadastro/evento',
    name: 'CadastroEvento',
    component: () => import('../views/cadastros/UsuarioEvento.vue'),
    meta: {
      unauthenticated: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.unauthenticated) {
    return next()
  }

  /* const isAuthenticated = store.getters.isAuthenticated

  if (!isAuthenticated) {
    console.log(`401: not authorized to ${to.fullPath}`)
    return next('/cadastrousuario')
  }

  if (to.meta?.role) {
    const inRole = !!store.state.roles.find(r => r === to.meta.role)
    if (!inRole) {
      console.log(`401: not authorized enough to ${to.fullPath}`)
      return next('/cadastrousuario')
    }
  } */

  next()
})

export default router
